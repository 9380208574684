import React, { useCallback, useState } from "react";
import "../../style/auth/verifyCertificate.scss";
import axios from "axios";

const VerifyCertificate = ({ activeToDownload, userRollNumber = "" }) => {
  const [getCertificateNo, setCertificateNo] = useState("");
  const [certificateData, setCertificateData] = useState([]);
  const [displayMessage, setDisplayMessage] = useState(
    "Validate Your Certificate now."
  );
  const [iconFlag, setIconFalg] = useState("");

  const helperFunctionForState = (
    displayMessageValue,
    iconFlagValue,
    certificateDataValue
  ) => {
    setCertificateData(certificateDataValue);
    setDisplayMessage(displayMessageValue);
    setIconFalg(iconFlagValue);
  };

  const fetchCertificateFromDatabase = useCallback(() => {
    if (getCertificateNo !== "") {
      helperFunctionForState("Validating Your Certificate.....", "", []);
      axios
        .get(
          `https://kheci-api.vercel.app/api/verifyCertificate?certificate=${getCertificateNo}`,
          {
            headers: {
              Authorization: "RKUHPEECSIH",
            },
          }
        )
        .then((res) => {
          if (res?.data?.length > 0) {
            helperFunctionForState(
              "Your Certificate is Valid",
              "check",
              res?.data
            );
          } else {
            helperFunctionForState("Your Certificate is Invalid", "xmark", []);
          }
        })
        .catch((err) => {
          helperFunctionForState(
            "Something went wrong!\nPlease try again",
            "",
            []
          );
          alert("Something went wrong!\nPlease try again");
        });
    } else {
      alert("Please enter certificate number for validate!");
    }
  }, [getCertificateNo]);

  return (
    <div className="verifyCertificateContainer">
      <div className="verifyCertificateHeader">Verify Certificate</div>
      <div className="verifyCertificateInput">
        <label htmlFor="certificateInput">Enter Certificate No: </label>
        <input
          id="certificateInput"
          type="text"
          placeholder="eg: 10XX"
          value={getCertificateNo}
          onChange={(e) => {
            e.preventDefault();
            setCertificateNo(e.currentTarget.value);
          }}
        />
        <button className="verifyButton" onClick={fetchCertificateFromDatabase}>
          Submit
        </button>
      </div>
      <div className="verifyIconText">
        {displayMessage}
        <i
          className={`fa-solid fa-circle-${iconFlag}`}
          style={{
            marginLeft: "5px",
            color: iconFlag === "check" ? "green" : "red",
          }}
        ></i>
      </div>
      {certificateData.length > 0 ? (
        <div className="verifyCertificateData">
          <table className="table table-bordered border-dark">
            <thead className="table-light">
              <tr
                className="certificateTableHeaderText"
                style={{ textAlign: "center" }}
              >
                <th scope="col">Name</th>
                <th scope="col">Course</th>
                <th scope="col">Duration</th>
                <th scope="col">Year</th>
              </tr>
            </thead>
            <tbody>
              {certificateData?.map((item, index) => {
                return (
                  <tr
                    key={index}
                    id={index}
                    className="certificateTableDataText"
                    style={{
                      textAlign: "center",
                      border: "1px dashed silver",
                    }}
                  >
                    <td>{item?.NAME}</td>
                    <td>{`${item?.COURSE} (${item?.COURSE_NAME})`}</td>
                    <td>{item?.MONTH}</td>
                    <td>{item?.YEAR}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : null}

      {activeToDownload ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "30px",
          }}
        >
          <button
            className="optionButton"
            style={{
              background: true
                ? "linear-gradient(45deg, #1b406a, black)"
                : "linear-gradient(45deg, #ebebeb, #d5e5e9)",
              color: true ? "white" : "black",
            }}
            onClick={(e) => {
              window?.open(
                `https://kheci.org/computer_student_certificate/${userRollNumber}.pdf`,
                "_blank"
              );
            }}
          >
            <i
              className={`fa-solid fa-profile`}
              style={{ marginRight: "5px" }}
            ></i>
            {"Download Your Certificate!"}
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default VerifyCertificate;
