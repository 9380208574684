import axios from "axios";
import { useEffect, useState } from "react";

const Test = () => {
  const [data, setData] = useState();

  const fetchPage = async () => {
    const fPage = await axios.get(
      "https://github-readme-design.vercel.app/api",
      {
        headers: {
          //   Accept: "text/html",
          Accept: "*/*",
        },
      }
    );

    console.log(fPage);
    var parser = new DOMParser();
    var doc = parser.parseFromString(fPage.data, "*/*");
    // var pre = doc.querySelector("div");
    console.log("doc", doc);
    setData(doc);
  };

  useEffect(() => {
    // fetchPage();
  }, []);

  return <div>{data}</div>;
};

export default Test;
