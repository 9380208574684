import React, { useState } from "react";
import "../style/loginComp.scss";
import SplashModal from "./SplashModal";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { LoginDetailsSchema } from "../schema/index";

const LoginComponent = () => {
  const navigate = useNavigate();
  const [cpatchaFunction, setCpatchaFunction] = useState(
    `${Math.floor(Math.random() * 9) + 1}${Math.floor(Math.random() * 9) + 1}${
      Math.floor(Math.random() * 9) + 1
    }${Math.floor(Math.random() * 9) + 1}`
  );

  const [loading, setLoading] = useState(false);

  const INITIAL_VALUE = {
    course: "",
    roll_no: "",
    dob: "",
    captcha: "",
  };

  const { handleSubmit, handleChange, touched, resetForm, values, errors } =
    useFormik({
      initialValues: INITIAL_VALUE,
      validationSchema: LoginDetailsSchema,
      onSubmit: (value, action) => {
        setLoading(true);
        if (value?.captcha === cpatchaFunction) {
          if (value?.course.toLowerCase() === "comp") {
            navigate(`/auth/comp/${value?.roll_no}`);
            action.resetForm();
          } else if (value?.course.toLowerCase() === "bed") {
            navigate(`/auth/bed/${value?.roll_no}`);
            action.resetForm();
          } else if (value?.course.toLowerCase() === "deled") {
            navigate(`/auth/deled/${value?.roll_no}`);
            action.resetForm();
          }
        } else {
          setTimeout(() => {
            setLoading(false);
            alert("Captcha not matched!");
          }, 1500);
        }
      },
    });

  return (
    <div className="loginContainer">
      <div className="loginContainerOne">
        <div className="loginDesignContainer">
          <img
            src="/loginImage.webp"
            alt="login"
            style={{
              width: "100%",
              display: "block",
            }}
          />
        </div>
      </div>
      <div className="loginContainerTwo">
        <div className="loginSection">
          <div
            style={{
              width: "100%",
              height: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "20px",
              fontWeight: "bold",
              background: "linear-gradient(45deg, red, blue)",
              color: "whitesmoke",
            }}
          >
            Student Console
          </div>
          {/*  */}
          <div className="eachFieldContainer">
            <label htmlFor="course">Course:</label>
            <select
              name="course"
              id="course"
              style={{ width: "100%", height: "100%", cursor: "pointer" }}
              value={values.course}
              onChange={handleChange}
            >
              <option value="">Select Course</option>
              <option value="bed">{"Bachelor of Education (B.Ed)"}</option>
              <option value="deled">
                {"Diploma In Elementary Education (DELEd)"}
              </option>
              <option value="comp">
                {"Computer Course (C.C.A, A.D.C.A, D.C.A, D.F.A, etc)"}
              </option>
            </select>
            {errors?.course && touched.course && (
              <p style={{ margin: "0px", color: "red" }}>{errors?.course}</p>
            )}
          </div>
          {/*  */}
          <div className="eachFieldContainer">
            <label htmlFor="roll_no">Enrollment No:</label>
            <input
              name="roll_no"
              id="roll_no"
              type="text"
              placeholder="Enter Your Enrollment No"
              style={{ width: "100%", height: "100%" }}
              value={values.roll_no}
              onChange={handleChange}
            />
            {errors?.roll_no && touched.roll_no && (
              <p style={{ margin: "0px", color: "red" }}>{errors?.roll_no}</p>
            )}
          </div>
          <div className="eachFieldContainerText" style={{ fontSize: "14px" }}>
            {values?.course === ""
              ? "If You don't have your Enrollment No please contact +91 9832368234"
              : values?.course === "comp"
              ? "Eg: 22A01-XXXX"
              : "Eg: 223XXXX"}
          </div>
          {/*  */}
          <div className="eachFieldContainer">
            <label htmlFor="dob">Date Of Birth:</label>
            <input
              name="dob"
              id="dob"
              type="text"
              placeholder="Enter Your Date Of Birth"
              style={{ width: "100%", height: "100%" }}
              value={values.dob}
              onChange={handleChange}
            />
            {errors?.dob && touched.dob && (
              <p style={{ margin: "0px", color: "red" }}>{errors?.dob}</p>
            )}
          </div>
          <div className="eachFieldContainerText">
            {"Eg: DD-MM-YYYY , Like: 08-03-1998"}
          </div>
          <div className="captchaFieldContainer">
            <div
              style={{
                width: "50%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <label
                htmlFor="captcha"
                style={{
                  fontSize: "25px",
                  marginRight: "8px",
                  letterSpacing: "8px",
                  background:
                    "repeating-linear-gradient(50deg, #000000, #1b9bc600 1px)",
                  textAlign: "center",
                }}
              >
                {cpatchaFunction}
              </label>
              <a
                href="/#"
                onClick={(e) => {
                  e.preventDefault();
                  setCpatchaFunction(
                    `${Math.floor(Math.random() * 9) + 1}${
                      Math.floor(Math.random() * 9) + 1
                    }${Math.floor(Math.random() * 9) + 1}${
                      Math.floor(Math.random() * 9) + 1
                    }`
                  );
                }}
              >
                <i className="fa-solid fa-rotate-right"></i>
              </a>
            </div>
            <div
              style={{
                width: "50%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <input
                name="captcha"
                id="captcha"
                type="text"
                placeholder="Enter Captcha"
                style={{ width: "100%", height: "100%" }}
                maxLength={4}
                value={values.captcha}
                onChange={handleChange}
              />
              {errors?.captcha && touched.captcha && (
                <p style={{ margin: "0px", color: "red", fontSize: "11px" }}>
                  {errors?.captcha}
                </p>
              )}
            </div>
          </div>
          {/*  */}
          {/*  */}
          <div
            style={{
              width: "100%",
              height: "auto",
              margin: "5px 0px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <input
              type="reset"
              value="Reset"
              id="resetButtonDesign"
              onClick={resetForm}
            />
            <input
              type="submit"
              value="Search"
              id="submitButtonDesign"
              onClick={handleSubmit}
            />
          </div>
          {/*  */}
          <SplashModal display={loading} />
          {/*  */}
          <div
            style={{
              width: "100%",
              height: "auto",
              fontFamily: "Poppins",
              fontWeight: "400",
              fontSize: "12px",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              paddingRight: "5px",
            }}
          >{`Version: v1.2.0`}</div>
        </div>
      </div>
    </div>
  );
};

export default LoginComponent;
