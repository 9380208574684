import React from "react";
import "../style/error.scss";

const Error = () => {
  return (
    <div className="errorContainer">
      <h1 className="errorCode">404</h1>
      <div>Page you are looking is not exist!</div>
      <a href="/" style={{ color: "antiquewhite" }}>
        Back to Home
      </a>
    </div>
  );
};

export default Error;
