import React from "react";
import "../../style/auth/paymentDetails.scss";

const PaymentDetails = ({ feeData }) => {
  return (
    <div className="paymentDetailsContainer">
      <div className="paymentDetailsHeader">Payment Details</div>
      <div className="paymentDetailsData">
        <table className="table table-bordered border-dark">
          <thead className="table-light">
            <tr className="tableHeaderText" style={{ textAlign: "center" }}>
              <th scope="col">Particulars</th>
              <th scope="col">Description</th>
              <th scope="col">Amount</th>
              <th scope="col">Date</th>
            </tr>
          </thead>
          <tbody>
            {feeData?.map((item, index) => {
              return (
                <tr
                  key={index}
                  id={index}
                  className="tableDataText"
                  style={{
                    textAlign: "center",
                    border: "1px dashed silver",
                  }}
                >
                  <td>{item?.PARTICULARS}</td>
                  <td>{item?.FEES_DESCRIPTION}</td>
                  <td>{item?.FEES_AMOUNT}</td>
                  <td>{item?.FEES_DATE}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {feeData?.length === 0 ? (
          <div className="paymentDetailsError">
            Sorry no payment data to show!
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PaymentDetails;
