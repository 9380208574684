import React from "react";
import "../style/navbar.scss";

const Navbar = ({ linkTitle, linkTitleURL, linkIcon }) => {
  return (
    <div className="studentHeader">
      <div className="studentHeaderOne">
        <img
          src="/kheci.svg"
          alt="kheci"
          style={{
            width: "100%",
            height: "80px",
            display: "block",
            // backgroundColor: "green",
          }}
        />
      </div>
      <div className="studentHeaderTwo">
        {/* Update Soon */}
        <ul className="studentHeaderNav">
          <li>
            <i
              className={`fa-solid ${linkIcon}`}
              style={{ marginRight: "5px" }}
            ></i>
            <a className="studentHeaderNavTextLink" href={linkTitleURL}>
              {linkTitle}
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
