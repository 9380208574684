import { useEffect } from "react";
import { Helmet } from "react-helmet";
import "../style/home/home.scss";
import "bootstrap/dist/css/bootstrap.min.css"; // Import bootstrap CSS
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import LoginComponent from "../components/LoginComponent";

export default function App() {
  useEffect(() => {
    require("bootstrap/dist/js/bootstrap.bundle.min.js");
    return () => {};
  }, []);

  return (
    <div className="homeContainer">
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="Student Detils Page"
          content="Designed & Developed by Kalimpong Himalayan Edu-Care Institution"
        />
        <link rel="icon" href="/favicon.ico" />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css"
          integrity="sha512-xh6O/CkQoPOWDdYTDqeRdPCVd1SpvCA9XXcUnZS2FmJNp1coAFzvtCN9BmamE+4aHK8yyUHUSCcJHgXloTyT2A=="
          crossorigin="anonymous"
          referrerpolicy="no-referrer"
        />
        <title>Student Details</title>
      </Helmet>

      <Navbar
        linkTitle={"Home"}
        linkTitleURL={"https://kheci.org/"}
        linkIcon={"fa-house"}
      />
      <LoginComponent />
      <Footer />
    </div>
  );
}
