import React from "react";
import "../style/footer.scss";

const Footer = () => {
  return (
    <div className="footerContainer">
      <div className="footerText">
        <span>from</span>
        <span>Kalimpong Himalayan Edu Care Institution</span>
      </div>
    </div>
  );
};

export default Footer;
