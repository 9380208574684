import React from "react";
import "../style/splashModal.scss";

const SplashModal = ({ display }) => {
  return (
    <div
      style={{ display: display ? "flex" : "none" }}
      className="splashLoaderContainer"
    >
      <span>{`Please Wait`}</span>
      <span className="loaderIcon">.....</span>
    </div>
  );
};

export default SplashModal;
