import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import "../style/auth/auth.scss";
import "bootstrap/dist/css/bootstrap.min.css"; // Import bootstrap CSS
import Navbar from "../components/Navbar";
import { useNavigate, useParams } from "react-router-dom";
import UserDetails from "../components/Auth/UserDetails";
import axios from "axios";
import PaymentDetails from "../components/Auth/PaymentDetails";
import VerifyCertificate from "../components/Auth/VerifyCertificate";
import PrintIdCard from "../components/Auth/PrintIdCard";

export default function Auth() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [feeData, setFeeData] = useState([]);
  const { course, enroll } = useParams();
  const [currentButtonActive, setCurrentButtonActive] = useState("Profile");
  const [errorMessage, setErrorMessage] = useState("Authenticating.....");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const backToBase = () => {
    setErrorMessage("User not registered!");
    setTimeout(() => {
      navigate(`/`);
    }, 1500);
  };

  const fetchDocFromDatabase = useCallback(() => {
    const URL =
      course === "comp"
        ? `https://kheci-api.vercel.app/api/test?roll=${enroll.toUpperCase()}`
        : course === "bed"
        ? `https://kheci-api.vercel.app/api/bedStudent/getStudent?roll=${enroll.toUpperCase()}`
        : course === "deled"
        ? `https://kheci-api.vercel.app/api/deledStudent/getStudent?roll=${enroll.toUpperCase()}`
        : "";
    if (URL !== "") {
      axios
        .get(URL, {
          headers: {
            Authorization: "RKUHPEECSIH",
          },
        })
        .then((res) => {
          res?.data?.length > 0 ? setData(res?.data) : backToBase();
        })
        .catch((err) => {
          alert("Something went wrong!\nPlease try again");
          backToBase();
        });
    } else {
      setErrorMessage(
        "Someone is not authorized user or student! going back to safe mode."
      );
      backToBase();
    }
  }, [backToBase, course, enroll]);

  const fetchFeesFromDatabase = useCallback(() => {
    const URI =
      course === "comp"
        ? `https://kheci-api.vercel.app/api/other/student_fees/fetchFees?roll=${enroll?.toUpperCase()}`
        : course === "bed"
        ? `https://kheci-api.vercel.app/api/bedStudent/student_fees/fetchFees?roll=${enroll?.toUpperCase()}`
        : course === "deled"
        ? `https://kheci-api.vercel.app/api/deledStudent/student_fees/fetchFees?roll=${enroll?.toUpperCase()}`
        : "";
    if (URI !== "") {
      axios
        .get(URI, {
          headers: {
            Authorization: "RKUHPEECSIH",
          },
        })
        .then((res) => {
          setFeeData(res?.data);
        })
        .catch((err) => {
          alert("Something went wrong!\nPlease try again");
          backToBase();
        });
    }
  }, [backToBase, course, enroll]);

  const optionBlock = [
    {
      title: "bed",
      options: ["Profile", "Payment Details"],
      icons: ["user", "indian-rupee-sign"],
    },
    {
      title: "deled",
      options: ["Profile", "Payment Details"],
      icons: ["user", "indian-rupee-sign"],
    },
    {
      title: "comp",
      options: ["Profile", "Payment Details", "ID Card", "Verify Certificate"],
      icons: ["user", "indian-rupee-sign", "id-card", "circle-check"],
    },
  ];

  const OptionComponent = () => {
    return (
      <div className="optionButtonContainer">
        {
          // eslint-disable-next-line array-callback-return
          optionBlock?.map((item, index) => {
            if (item?.title === course) {
              return item?.options?.map((eachItem, eachIndex) => (
                <button
                  key={`eachBtn${eachIndex}`}
                  className="optionButton"
                  style={{
                    background:
                      eachItem === currentButtonActive
                        ? "linear-gradient(45deg, #1b406a, black)"
                        : "linear-gradient(45deg, #ebebeb, #d5e5e9)",
                    color: eachItem === currentButtonActive ? "white" : "black",
                  }}
                  onClick={(e) => {
                    setCurrentButtonActive(eachItem);
                    if (eachItem?.toLowerCase() === "payment details") {
                      fetchFeesFromDatabase();
                    }
                  }}
                >
                  <i
                    className={`fa-solid fa-${item?.icons[eachIndex]}`}
                    style={{ marginRight: "5px" }}
                  ></i>
                  {eachItem}
                </button>
              ));
            }
          })
        }
      </div>
    );
  };

  useEffect(() => {
    require("bootstrap/dist/js/bootstrap.bundle.min.js");

    fetchDocFromDatabase();
    fetchFeesFromDatabase();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course, enroll]);

  return (
    <div className="authContainer">
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="Student Dashboard"
          content="Designed & Developed by Kalimpong Himalayan Edu-Care Institution"
        />
        <link rel="icon" href="/favicon.ico" />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css"
          integrity="sha512-xh6O/CkQoPOWDdYTDqeRdPCVd1SpvCA9XXcUnZS2FmJNp1coAFzvtCN9BmamE+4aHK8yyUHUSCcJHgXloTyT2A=="
          crossorigin="anonymous"
          referrerpolicy="no-referrer"
        />
        <title>Dashboard</title>
      </Helmet>

      <Navbar
        linkTitle={"Logout"}
        linkTitleURL={"https://student.kheci.org/"}
        linkIcon={"fa-user"}
      />
      {data?.length > 0 ? (
        <React.Fragment>
          <div className="mainLayer">
            {currentButtonActive.toLowerCase() === "profile" ? (
              <UserDetails
                enroll={enroll.toUpperCase()}
                course={course}
                userData={data}
              />
            ) : currentButtonActive.toLowerCase() === "payment details" ? (
              <PaymentDetails feeData={feeData} />
            ) : currentButtonActive.toLowerCase() === "id card" ? (
              <PrintIdCard data={data} />
            ) : currentButtonActive.toLowerCase() === "verify certificate" ? (
              <VerifyCertificate
                activeToDownload={
                  data?.[0]?.CERTIFICATE_NO === "" ? false : true
                }
                userRollNumber={data?.[0]?.ROLL_NO}
              />
            ) : (
              <div>{"Something went wrong, Please try again!"}</div>
            )}
          </div>
          <div className="noteContainer">
            Note: All showing data is on or after 1st May 2023 | Old data is not
            digitilized please contact +91 9832368234 for more details.
          </div>
          <OptionComponent />
        </React.Fragment>
      ) : (
        <div className="errorMessage">{errorMessage}</div>
      )}
    </div>
  );
}
