import QRCode from "react-qr-code";
import "../../style/auth/printIdCard.scss";
import { useEffect, useState } from "react";
function PrintIdCard({ data }) {
  const [getFlag, setFlag] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setFlag(true);
    }, 1500);
    return () => {};
  }, []);
  return (
    <>
      {getFlag ? (
        <div className="printIdCardWrapper">
          <div className="printIdContainer">
            <img
              src={`https://kheci-api.vercel.app/api/idCard?ROLL_NO=${data[0]?.ROLL_NO}`}
              alt={`https://kheci-api.vercel.app/api/idCard?ROLL_NO=${data[0]?.ROLL_NO}`}
            />

            {/* QRCode */}
            <div
              style={{
                height: "auto",
                margin: "0 auto",
                maxWidth: 58,
                width: "100%",
                marginTop: "-245px",
                marginRight: "6px",
              }}
            >
              <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={`ROLL_NO: ${data[0]?.ROLL_NO},NAME: ${data[0]?.NAME},FATHER_NAME: ${data[0]?.FATHER_NAME},MOTHER_NAME: ${data[0]?.MOTHER_NAME},COURSE: ${data[0]?.COURSE},DATE_OF_BIRTH: ${data[0]?.DATE_OF_BIRTH},DATE_OF_ADMISSION: ${data[0]?.DATE_OF_ADMISSION},ADDRESS: ${data[0]?.ADDRESS}`}
                viewBox={`0 0 256 256`}
              />
            </div>

            {/* Image */}
            <div
              style={{
                height: "auto",
                margin: "0 auto",
                maxWidth: 58,
                width: "100%",
                marginTop: "6px",
                marginRight: "63px",
              }}
            >
              <img
                src={`https://kheci.org/computer_student_images/${data[0]?.ROLL_NO}.jpg`}
                width={"96px"}
                height={"116px"}
                alt={`https://kheci.org/computer_student_images/${data[0]?.ROLL_NO}.jpg`}
              />
            </div>

            {/* Stamp */}
            <div
              style={{
                height: "auto",
                margin: "0 auto",
                maxWidth: 58,
                width: "100%",
                marginTop: "-140px",
                marginRight: "99px",
              }}
            >
              <img
                src={"https://kheci-api.vercel.app/stamp.svg"}
                width={"70px"}
                height={"70px"}
                alt={"https://kheci-api.vercel.app/stamp.svg"}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="showDisplayMessage">Generating ID Card.....</div>
      )}
    </>
  );
}

export default PrintIdCard;
